.button-primary{
	font-family: $poppinssemibold;
	@include font-size(15,20);
	color:$white !important;
	background: $black;
	display: inline-block;
	padding: 15px 25px;
	border-radius: 25px;
    min-width: 180px;
    text-align: center;
    @include transition;
    &:hover{
    	min-width:200px;
    }
    i{
    	&.icon-right-arrow{
    		color:$yellow;
    		font-size: 18px;
    		margin-right: 6px;
    	}
    }
}

.button-secondary{
    font-family: $poppinssemibold;
    @include font-size(13,18);
    color:$yellow !important;
    padding: 12px 25px;
    border:1px solid $yellow;
    display: inline-block;
    border-radius: 30px;
    min-width: 157px;
    text-align: center;
}