html{min-height: 100%;min-width: 100%; font-size: 62.5%;}
body{width: 100%;height: 100%;margin: 0;padding: 0 !important;min-height: 100%;min-width: 100%;background:#ffffff;}
*::selection {background: #000000;color: #ffffff}

/*************************reset-erd*************************/
h1, h2, h3, h4, h5, h6, th,p,label,figure{font-weight: normal;margin:0;}
a{text-decoration: none !important}
ul{list-style: none;padding: 0;margin: 0}
input, select, textarea, button, a {outline: none !important;border:none;}
select{-webkit-appearance: none;-webkit-border-radius:0;border-radius:0;}
input, select, textarea{background: #ffffff;border-radius:0}
input[type="text"]{-webkit-appearance: none;-moz-appearance: none;appearance: none;}
input[type=text]::-ms-clear { display: none; }
i{display: inline-block;vertical-align: middle;}
nav {background: none;border: none;margin: 0;min-height: auto !important;}
nav button.navbar-toggle {background: none !important;border: none !important}
nav.navbar-expand-lg .navbar-nav a.nav-link{padding: 0;margin:0;}
.navbar-collapse {border: none !important;box-shadow: 0 0 0 0 !important;padding: 0}
.navbar-brand{padding: 0;height: auto}
.dropdown button{box-shadow: 0 0 0 0 !important;border:none;padding:0}
/*************************reset-erd*************************/