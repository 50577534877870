.departments{
	padding: 54px 0 25px 0;
	.row{
		> div{
			margin-bottom: 36px;
		}
	}
	&__wrap{
		height: 100%;
		background: $lightgrey;
		figure{
			width: 100%;
			height: 212px;
			position: relative;
			&:before{
				content:"";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 70%;
				height: 5px;
				background: $yellow;
				@include transition;
			}
		}
		section{
			padding: 24px 22px;
			h5{
				font-family: $poppinssemibold;
				@include font-size(20,28);
				color:$fontblack;
				margin-bottom: 12px;
				span{
					display: inline-block;
					font-family: $poppinslight;
				}
			}
			p{
				font-family: $poppinslight;
				@include font-size(12,14);
				color:$fontgrey;
				position: relative;
				padding-left: 15px;
				&:before{
					content:"";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 4px 0 4px 8px;
					border-color: transparent transparent transparent $yellow;
					position: absolute;
					left: 0;
					top: 50%;
					margin-top: -4px;
				}
			}
		}
		a{
			display: block;
			height: 100%;
		}
		&:hover{
			figure{
				&:before{
					width:40%;
				}
			}
		}
	}
}