//......................................
//= PATHS
//......................................

$images 				:	'../images';
$fonts					:	'../fonts';

$poppinsbold			:	'Conv_Poppins-Bold';
$poppinsmedium			:	'Conv_Poppins-Medium';
$poppinssemibold		:	'Conv_Poppins-SemiBold';
$poppinsregular			:	'Conv_Poppins-Regular';
$poppinslight			:	'Conv_Poppins-Light';
$robotobold				:	'Conv_Roboto-Bold';
$robotomedium			:	'Conv_Roboto-Medium';
$robotoregular			:	'Conv_Roboto-Regular';
$robotolight			:	'Conv_Roboto-Light';

//COLORS		
$black					:	#000;
$fontblack				:	#040707;
$fontgrey				:	#7a7a7a;
$white					:	#fff;
$yellow					:	#f2ca10;
$grey					:	#ebebeb;
$lightgrey				:	#f7f7f7;
$fontsecondgrey			:	#2d2d2d;
$contactfont			:	#505050;

//BREAKPOINTS
$lg-width				: 1200px;
$md-width				: 991px;
$sm-width				: 767px;
$xs-width				: 576px;

$mobile-width			: 375px;