.accordion-page{
	padding: 50px 0;
	.accordion{
		.card{
			border-bottom: 1px solid #dfdfdf;
			&:not(:last-child){
				margin-bottom: 13px;
			}
			.card-header{
				padding: 0;
				button{
					font-family: $robotoregular;
					@include font-size(16,24);
					color:$fontsecondgrey;
					background: $lightgrey;
				    padding: 17px 200px 17px 28px;
				    width: 100%;
				    text-align: left;
				    text-decoration: none;
				    outline: none;
				    box-shadow: 0 0 0 0;
			        display: flex;
				    justify-content: space-between;
				    align-items: center;
				    position: relative;
				    @media (max-width: $md-width){
				    	padding-right: 80px;
				    	padding-top: 50px;
				    	padding-left: 15px;
				    }
				    &:before{
				    	content:"\e900";
				    	font-family: 'icomoon';
				    	font-size: 22px;
				    	color:$yellow;
				    	transform: rotate(90deg);
				    	right: 25px;
				    	top: 50%;
				        position: absolute;
    					margin-top: -11px;
				    }
				    &:after{
				    	content:"";
				    	position: absolute;
				    	top: 0;
				    	right: 65px;
				    	height: 100%;
				    	width: 1px;
				    	background: #dfdfdf;
				    }
				    span{
				    	&.date{
				    		font-family: $robotoregular;
				    		@include font-size(13,20);
				    		color:$fontsecondgrey;
				    		position: absolute;
				    		right: 90px;
				    		@media (max-width: $md-width){
				    			right: auto;
							    left: 15px;
							    top: 15px;
				    		}
				    		i{
				    			font-size: 23px;
				    			color:$yellow;
				    			margin-right: 7px;
				    		}
				    	}
				    }
				}
			}
			.card-body{
				padding:31px 30px 42px 30px;
				font-family: $robotolight;
				@include font-size(13,20);
				color:$fontsecondgrey;
				@media (max-width: $md-width){
					padding:30px 15px;
				}
			}
		}
	}
	&.test-results{
		.accordion{
			.card{
				border:none;
				.card-header{
					border: 1px solid #dfdfdf;
					margin-bottom: 0;
					button{
						@media (max-width: $md-width){
					    	padding-top: 17px;
					    }
					}
					span{
						&.date{
							position: initial;
							font-family: $robotomedium;
							@include font-size(16,24);
							i{
								margin-right: 8px;
							}
						}
					}
				}
				.card-body{
					padding: 15px 0;
					img{
						max-width: 100%;
						width: 100%;
					}
				}
			}
		}
	}
}