footer{
	padding:47px 0 37px 0;
	@media (max-width: $lg-width){
		padding: 30px 0;
	}
	&.grey{
		background: $lightgrey;
	}
	.row{
		justify-content: space-between;
		align-items: center;
		> div{
			@media (max-width: $lg-width){
				flex: 0 0 100%;
    			max-width: 100%;
			}
		}
	}
	.footer__left{
		display: flex;
		@media (max-width: $lg-width){
			align-items: center;
			flex-direction: column;
		}
		figure{
			margin-right: 47px;
			@media (max-width: $lg-width){
				margin-right: 0;
				margin-bottom: 15px;
			}
			img{
				max-width: 51px;
				width: 100%;
			}
		}
		p{
			font-family: $poppinsregular;
			@include font-size(12,16);
			color:$fontblack;
			padding-top: 11px;
			@media (max-width: $lg-width){
				text-align: center;
			}
			&.footer__left_contact{
				font-family: $robotomedium;
				@include font-size(14,21);
				color:$fontblack;
				margin-top: 35px;
				padding-top: 0;
				@media (max-width: $sm-width){
					margin-top: 20px;
				}
				i{
					font-size: 28px;
					color:$yellow;
					margin-right: 7px;
					&.mail{
						font-size: 21px;
					}
				}
			}
		}
	}
	ul{
		display: flex;
		align-items: center;
		float: right;
		@media (max-width: $lg-width){
			justify-content: center;
			float: none;
			margin-top: 15px;
			flex-direction: column;
		}
		li{
			&:not(:last-child){
				padding-right: 30px;
				@media (max-width: $lg-width){
					padding-right: 0;
					padding-bottom: 15px;
				}
			}
			a{
				font-family: $robotomedium;
				@include font-size(14,21);
				color:$fontblack !important;
				i{
					margin-right: 8px;
					color:$yellow;
					font-size: 28px;
					&.icon-mail{
						font-size: 21px;
					}
				}
				img{
					max-width: 89px;
					width: 100%;
				}
			}
		}
	}
}