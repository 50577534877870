.sub-banner{
	@extend .bg;
	height: 260px;
	width: 100%;
	position: relative;
	padding-top: 200px;
	overflow: hidden;
	&:before{
		content:"";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: $black;
		opacity: 0.8;
	}
	h2{
		font-family: $poppinslight;
		@include font-size(35,42);
		color:$white;
		position: relative;
		animation-duration: var(--animate-duration);
		animation-fill-mode: both;
		animation-name: fadeInUp;
		span{
			display: inline-block;
			font-family: $poppinssemibold;
		}
	}
}

#map{
	width: 100%;
	height: 460px;
}