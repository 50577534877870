[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cloud:before {
  content: "\e910";
}
.icon-confetti:before {
  content: "\e909";
}
.icon-security-camera:before {
  content: "\e90a";
}
.icon-shield:before {
  content: "\e90f";
}
.icon-calendar:before {
  content: "\e904";
}
.icon-direction:before {
  content: "\e905";
}
.icon-envelope:before {
  content: "\e906";
}
.icon-location:before {
  content: "\e907";
}
.icon-phone-call:before {
  content: "\e908";
}
.icon-send:before {
  content: "\e90b";
}
.icon-stats:before {
  content: "\e90c";
}
.icon-turn:before {
  content: "\e90d";
}
.icon-worker:before {
  content: "\e90e";
}
.icon-arrow-right:before {
  content: "\e900";
}
.icon-coffee:before {
  content: "\e901";
}
.icon-contact:before {
  content: "\e902";
}
.icon-dish:before {
  content: "\e903";
}
.icon-heart:before {
  content: "\e911";
}
.icon-mail:before {
  content: "\e912";
}
.icon-phone:before {
  content: "\e913";
}
.icon-right-arrow:before {
  content: "\e914";
}
.icon-tent:before {
  content: "\e915";
}
.icon-tray:before {
  content: "\e916";
}