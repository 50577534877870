.content-about-us{
	padding: 65px 0 32px 0;
	@media (max-width: $md-width){
		padding:40px 0 30px 0;
	}
	section{
		margin-bottom: 70px;
		@media (max-width: $md-width){
			margin-bottom: 40px;
		}
		p{
			font-family: $poppinsregular;
			@include font-size(14,20);
			color:$fontblack;
			b{
				font-family: $poppinssemibold;
			}
		}
	}
	h3{
		font-family: $poppinsbold;
		@include font-size(20,28);
		color:$fontblack;
		margin-bottom: 20px;
	}
	ul{
		@media (max-width: $md-width){
			margin-bottom: 30px;
		}
		li{
			font-family: $poppinsregular;
			@include font-size(14,20);
			color:$fontblack;
			position: relative;
			padding-left: 24px;
			&:not(:last-child){
				padding-bottom: 7px;
			}
			&:before{
				content:"";
				position: absolute;
				left: 5px;
				top: 7px;
				width: 6px;
				height: 6px;
				background: $yellow;
			}
		}
	}
	.ul_list{
		display: flex;
		@media (max-width: $sm-width){
			flex-direction: column;
		}
		ul{
			&:not(:last-child){
				padding-right: 40px;
			}
			@media (max-width: $md-width){
				margin-bottom:7px;
			}
		}
	}
}