@charset "UTF-8";
html {
  min-height: 100%;
  min-width: 100%;
  font-size: 62.5%; }

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 !important;
  min-height: 100%;
  min-width: 100%;
  background: #ffffff; }

*::selection {
  background: #000000;
  color: #ffffff; }

/*************************reset-erd*************************/
h1, h2, h3, h4, h5, h6, th, p, label, figure {
  font-weight: normal;
  margin: 0; }

a {
  text-decoration: none !important; }

ul {
  list-style: none;
  padding: 0;
  margin: 0; }

input, select, textarea, button, a {
  outline: none !important;
  border: none; }

select {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0; }

input, select, textarea {
  background: #ffffff;
  border-radius: 0; }

input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input[type=text]::-ms-clear {
  display: none; }

i {
  display: inline-block;
  vertical-align: middle; }

nav {
  background: none;
  border: none;
  margin: 0;
  min-height: auto !important; }

nav button.navbar-toggle {
  background: none !important;
  border: none !important; }

nav.navbar-expand-lg .navbar-nav a.nav-link {
  padding: 0;
  margin: 0; }

.navbar-collapse {
  border: none !important;
  box-shadow: 0 0 0 0 !important;
  padding: 0; }

.navbar-brand {
  padding: 0;
  height: auto; }

.dropdown button {
  box-shadow: 0 0 0 0 !important;
  border: none;
  padding: 0; }

/*************************reset-erd*************************/
[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-cloud:before {
  content: "\e910"; }

.icon-confetti:before {
  content: "\e909"; }

.icon-security-camera:before {
  content: "\e90a"; }

.icon-shield:before {
  content: "\e90f"; }

.icon-calendar:before {
  content: "\e904"; }

.icon-direction:before {
  content: "\e905"; }

.icon-envelope:before {
  content: "\e906"; }

.icon-location:before {
  content: "\e907"; }

.icon-phone-call:before {
  content: "\e908"; }

.icon-send:before {
  content: "\e90b"; }

.icon-stats:before {
  content: "\e90c"; }

.icon-turn:before {
  content: "\e90d"; }

.icon-worker:before {
  content: "\e90e"; }

.icon-arrow-right:before {
  content: "\e900"; }

.icon-coffee:before {
  content: "\e901"; }

.icon-contact:before {
  content: "\e902"; }

.icon-dish:before {
  content: "\e903"; }

.icon-heart:before {
  content: "\e911"; }

.icon-mail:before {
  content: "\e912"; }

.icon-phone:before {
  content: "\e913"; }

.icon-right-arrow:before {
  content: "\e914"; }

.icon-tent:before {
  content: "\e915"; }

.icon-tray:before {
  content: "\e916"; }

@font-face {
  font-family: 'Conv_Poppins-Bold';
  src: url("../fonts/Poppins-Bold.eot");
  src: local("☺"), url("../fonts/Poppins-Bold.woff") format("woff"), url("../fonts/Poppins-Bold.ttf") format("truetype"), url("../fonts/Poppins-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Poppins-Light';
  src: url("../fonts/Poppins-Light.eot");
  src: local("☺"), url("../fonts/Poppins-Light.woff") format("woff"), url("../fonts/Poppins-Light.ttf") format("truetype"), url("../fonts/Poppins-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Poppins-Medium';
  src: url("../fonts/Poppins-Medium.eot");
  src: local("☺"), url("../fonts/Poppins-Medium.woff") format("woff"), url("../fonts/Poppins-Medium.ttf") format("truetype"), url("../fonts/Poppins-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Poppins-Regular';
  src: url("../fonts/Poppins-Regular.eot");
  src: local("☺"), url("../fonts/Poppins-Regular.woff") format("woff"), url("../fonts/Poppins-Regular.ttf") format("truetype"), url("../fonts/Poppins-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Poppins-SemiBold';
  src: url("../fonts/Poppins-SemiBold.eot");
  src: local("☺"), url("../fonts/Poppins-SemiBold.woff") format("woff"), url("../fonts/Poppins-SemiBold.ttf") format("truetype"), url("../fonts/Poppins-SemiBold.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Blanka-Regular';
  src: url("../fonts/Blanka-Regular.eot");
  src: local("☺"), url("../fonts/Blanka-Regular.woff") format("woff"), url("../fonts/Blanka-Regular.ttf") format("truetype"), url("../fonts/Blanka-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Roboto-Bold';
  src: url("../fonts/Roboto-Bold.eot");
  src: local("☺"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype"), url("../fonts/Roboto-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Roboto-Medium';
  src: url("../fonts/Roboto-Medium.eot");
  src: local("☺"), url("../fonts/Roboto-Medium.woff") format("woff"), url("../fonts/Roboto-Medium.ttf") format("truetype"), url("../fonts/Roboto-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Roboto-Regular';
  src: url("../fonts/Roboto-Regular.eot");
  src: local("☺"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype"), url("../fonts/Roboto-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Roboto-Light';
  src: url("../fonts/Roboto-Light.eot");
  src: local("☺"), url("../fonts/Roboto-Light.woff") format("woff"), url("../fonts/Roboto-Light.ttf") format("truetype"), url("../fonts/Roboto-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?as1b3k");
  src: url("../fonts/icomoon.eot?as1b3k#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?as1b3k") format("truetype"), url("../fonts/icomoon.woff?as1b3k") format("woff"), url("../fonts/icomoon.svg?as1b3k#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

.bg, .sub-banner {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important; }

@keyframes spanColor {
  from {
    width: 0; }
  to {
    width: 60%; } }

@keyframes beforeBorderAnimate {
  from {
    height: 0; }
  to {
    height: 138px; } }

@keyframes afterBorderAnimate {
  from {
    width: 0; }
  to {
    width: 138px; } }

@keyframes facilitiesBorderBefore {
  0% {
    width: 70%; }
  25% {
    width: 40%; }
  50% {
    width: 50%; }
  100% {
    width: 70%; } }

@media (max-width: 767px) {
  @keyframes beforeBorderAnimate {
    from {
      height: 0; }
    to {
      height: 40px; } }
  @keyframes afterBorderAnimate {
    from {
      width: 0; }
    to {
      width: 40px; } } }

@media (max-width: 576px) {
  @keyframes afterBorderAnimate {
    from {
      width: 0; }
    to {
      width: 20px; } } }

.button-primary {
  font-family: "Conv_Poppins-SemiBold";
  font-size: 1.5rem;
  line-height: 2rem;
  color: #fff !important;
  background: #000;
  display: inline-block;
  padding: 15px 25px;
  border-radius: 25px;
  min-width: 180px;
  text-align: center;
  -webkit-transition: .4s ease-in-out;
  -moz-transition: .4s ease-in-out;
  -ms-transition: .4s ease-in-out;
  -o-transition: .4s ease-in-out;
  transition: .4s ease-in-out; }
  .button-primary:hover {
    min-width: 200px; }
  .button-primary i.icon-right-arrow {
    color: #f2ca10;
    font-size: 18px;
    margin-right: 6px; }

.button-secondary {
  font-family: "Conv_Poppins-SemiBold";
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: #f2ca10 !important;
  padding: 12px 25px;
  border: 1px solid #f2ca10;
  display: inline-block;
  border-radius: 30px;
  min-width: 157px;
  text-align: center; }

header {
  padding: 30px 0 0 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999; }
  @media (max-width: 991px) {
    header {
      padding: 20px 0 25px 0;
      background: rgba(0, 0, 0, 0.8); } }
  header nav {
    margin: 0;
    padding: 0 !important;
    align-items: flex-start !important; }
    header nav .navbar-brand img {
      max-width: 66px;
      width: 100%; }
      @media (max-width: 991px) {
        header nav .navbar-brand img {
          position: absolute;
          max-width: 45px; } }
    header nav .navbar-collapse {
      justify-content: space-around; }
    header nav button.navbar-toggler {
      padding: 0;
      margin: 0;
      height: 32px;
      width: 35px; }
      header nav button.navbar-toggler span.navbar-toggler-icon {
        width: 32px;
        height: 2.5px;
        background: #fff;
        position: relative; }
        header nav button.navbar-toggler span.navbar-toggler-icon:before, header nav button.navbar-toggler span.navbar-toggler-icon:after {
          content: "";
          width: 32px;
          height: 2.5px;
          background: #fff;
          position: absolute;
          display: inline-block;
          left: 0; }
        header nav button.navbar-toggler span.navbar-toggler-icon:before {
          top: -10px; }
        header nav button.navbar-toggler span.navbar-toggler-icon:after {
          bottom: -10px; }
    header nav ul {
      display: flex;
      padding-top: 25px; }
      @media (max-width: 991px) {
        header nav ul {
          padding-top: 75px; } }
      header nav ul li {
        padding: 0 12.5px; }
        header nav ul li:last-child {
          padding-right: 0; }
        @media (max-width: 991px) {
          header nav ul li {
            padding: 4px 0; } }
        header nav ul li a {
          font-family: "Conv_Roboto-Bold";
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: #fff;
          -webkit-transition: .4s ease-in-out;
          -moz-transition: .4s ease-in-out;
          -ms-transition: .4s ease-in-out;
          -o-transition: .4s ease-in-out;
          transition: .4s ease-in-out; }
          header nav ul li a:hover {
            color: #f2ca10; }
        header nav ul li.active a {
          color: #f2ca10; }
        header nav ul li.xs-visible {
          display: none; }
          @media (max-width: 991px) {
            header nav ul li.xs-visible {
              display: block; } }
      header nav ul.navbar-nav {
        margin: 0 auto; }
      @media (max-width: 991px) {
        header nav ul.nav-right {
          display: none; } }
      header nav ul.nav-right li a {
        font-size: 1.4rem;
        line-height: 2.1rem; }
        header nav ul.nav-right li a i {
          font-size: 28px;
          color: #f2ca10;
          margin-right: 9px; }

footer {
  padding: 47px 0 37px 0; }
  @media (max-width: 1200px) {
    footer {
      padding: 30px 0; } }
  footer.grey {
    background: #f7f7f7; }
  footer .row {
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 1200px) {
      footer .row > div {
        flex: 0 0 100%;
        max-width: 100%; } }
  footer .footer__left {
    display: flex; }
    @media (max-width: 1200px) {
      footer .footer__left {
        align-items: center;
        flex-direction: column; } }
    footer .footer__left figure {
      margin-right: 47px; }
      @media (max-width: 1200px) {
        footer .footer__left figure {
          margin-right: 0;
          margin-bottom: 15px; } }
      footer .footer__left figure img {
        max-width: 51px;
        width: 100%; }
    footer .footer__left p {
      font-family: "Conv_Poppins-Regular";
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: #040707;
      padding-top: 11px; }
      @media (max-width: 1200px) {
        footer .footer__left p {
          text-align: center; } }
      footer .footer__left p.footer__left_contact {
        font-family: "Conv_Roboto-Medium";
        font-size: 1.4rem;
        line-height: 2.1rem;
        color: #040707;
        margin-top: 35px;
        padding-top: 0; }
        @media (max-width: 767px) {
          footer .footer__left p.footer__left_contact {
            margin-top: 20px; } }
        footer .footer__left p.footer__left_contact i {
          font-size: 28px;
          color: #f2ca10;
          margin-right: 7px; }
          footer .footer__left p.footer__left_contact i.mail {
            font-size: 21px; }
  footer ul {
    display: flex;
    align-items: center;
    float: right; }
    @media (max-width: 1200px) {
      footer ul {
        justify-content: center;
        float: none;
        margin-top: 15px;
        flex-direction: column; } }
    footer ul li:not(:last-child) {
      padding-right: 30px; }
      @media (max-width: 1200px) {
        footer ul li:not(:last-child) {
          padding-right: 0;
          padding-bottom: 15px; } }
    footer ul li a {
      font-family: "Conv_Roboto-Medium";
      font-size: 1.4rem;
      line-height: 2.1rem;
      color: #040707 !important; }
      footer ul li a i {
        margin-right: 8px;
        color: #f2ca10;
        font-size: 28px; }
        footer ul li a i.icon-mail {
          font-size: 21px; }
      footer ul li a img {
        max-width: 89px;
        width: 100%; }

.main-hero {
  position: relative; }
  .main-hero:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.8;
    z-index: 1; }
  .main-hero figure {
    height: 718px;
    width: 100%; }
    @media (max-width: 991px) {
      .main-hero figure {
        height: 550px; } }
  .main-hero__title {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate(0, -50%);
    z-index: 10; }
    .main-hero__title h1, .main-hero__title p {
      max-width: 330px; }
    .main-hero__title h1 {
      font-family: "Conv_Poppins-SemiBold";
      font-size: 4.5rem;
      line-height: 5.4rem;
      color: #fff;
      letter-spacing: 1.48px;
      margin-bottom: 15px; }
      @media (max-width: 991px) {
        .main-hero__title h1 {
          font-size: 3.5rem;
          line-height: 4.4rem; } }
      .main-hero__title h1 span {
        display: inline-block;
        position: relative;
        padding-right: 5px; }
        .main-hero__title h1 span:nth-child(2):before {
          content: "";
          position: absolute;
          display: inline-block;
          right: 0;
          top: 0;
          background: #f2ca10;
          height: 100%;
          z-index: -1; }
    .main-hero__title p {
      font-family: "Conv_Poppins-Light";
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #fff;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin-bottom: 30px; }
  .main-hero ol.carousel-indicators {
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;
    justify-content: flex-start;
    bottom: 35px; }
    .main-hero ol.carousel-indicators li {
      width: 36px;
      height: 36px;
      text-indent: 0;
      font-family: "Conv_Poppins-Medium";
      font-size: 1.4rem;
      line-height: 2.1rem;
      color: #fff;
      background: none;
      border: none;
      text-align: center;
      opacity: 1;
      margin: 0;
      line-height: 2; }
      .main-hero ol.carousel-indicators li span {
        position: relative;
        display: inline-block;
        padding: 5px;
        width: 37px;
        height: 37px;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out; }
        .main-hero ol.carousel-indicators li span:before, .main-hero ol.carousel-indicators li span:after {
          content: "";
          width: 100%;
          height: 100%;
          border: 2px solid #f2ca10;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          opacity: 0;
          display: inline-block;
          transform: translate(-50%, -50%) rotate(-45deg);
          -webkit-transition: 1s ease-in-out;
          -moz-transition: 1s ease-in-out;
          -ms-transition: 1s ease-in-out;
          -o-transition: 1s ease-in-out;
          transition: 1s ease-in-out; }
        .main-hero ol.carousel-indicators li span:before {
          position: absolute;
          border-bottom-color: transparent;
          border-left-color: transparent; }
        .main-hero ol.carousel-indicators li span:after {
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 50%;
          border-top-color: transparent;
          border-right-color: transparent;
          border-bottom-color: transparent; }
      .main-hero ol.carousel-indicators li:not(:last-child) {
        padding-right: 30px; }
        @media (max-width: 991px) {
          .main-hero ol.carousel-indicators li:not(:last-child) {
            padding-right: 20px; } }
      .main-hero ol.carousel-indicators li:hover {
        color: #f2ca10; }
      .main-hero ol.carousel-indicators li.active {
        color: #f2ca10; }
        .main-hero ol.carousel-indicators li.active span:before, .main-hero ol.carousel-indicators li.active span:after {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(300deg); }
  .main-hero__phone {
    position: absolute;
    bottom: 35px;
    z-index: 1;
    left: 0;
    width: 100%;
    margin: 0 auto;
    right: 0; }
    @media (max-width: 767px) {
      .main-hero__phone {
        display: none; } }
    .main-hero__phone p {
      font-family: "Conv_Poppins-SemiBold";
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #fff;
      text-align: right; }
      .main-hero__phone p span {
        display: block;
        font-family: "Conv_Poppins-Light";
        color: #f2ca10; }
      .main-hero__phone p a {
        color: #fff; }
  .main-hero .carousel-inner h1 span, .main-hero .carousel-inner p, .main-hero .carousel-inner a {
    animation-duration: var(--animate-duration);
    animation-fill-mode: both; }
  .main-hero .carousel-inner h1 span {
    opacity: 0; }
    .main-hero .carousel-inner h1 span:before {
      animation-duration: var(--animate-duration);
      animation-fill-mode: both; }
  .main-hero .carousel-inner p {
    opacity: 0; }
  .main-hero .carousel-inner a {
    opacity: 0; }
  .main-hero .carousel-item.active h1 span {
    animation-name: fadeInLeft; }
    .main-hero .carousel-item.active h1 span:nth-child(1) {
      animation-delay: 0.4s; }
    .main-hero .carousel-item.active h1 span:nth-child(2) {
      animation-delay: 0.7s; }
    .main-hero .carousel-item.active h1 span:nth-child(3) {
      animation-delay: 1s; }
    .main-hero .carousel-item.active h1 span:before {
      animation-name: spanColor;
      animation-delay: 0.7s; }
  .main-hero .carousel-item.active p {
    animation-name: fadeInUp;
    animation-delay: 1s; }
  .main-hero .carousel-item.active a {
    animation-name: fadeInUp;
    animation-delay: 1.3s; }

.main-about-us .container {
  background: url(../images/content/main-about-us.jpg) no-repeat left top; }
  @media (max-width: 991px) {
    .main-about-us .container {
      background-size: auto;
      background-position: left 0px top -30px; } }

.main-about-us section {
  padding: 93px 0; }
  @media (max-width: 991px) {
    .main-about-us section {
      padding: 40px 0;
      margin-top: 320px; } }
  .main-about-us section h2, .main-about-us section p {
    color: #040707; }
  .main-about-us section h2 {
    font-family: "Conv_Poppins-SemiBold";
    font-size: 4.5rem;
    line-height: 5.4rem;
    margin-bottom: 32px; }
    @media (max-width: 767px) {
      .main-about-us section h2 {
        font-size: 3.5rem;
        line-height: 4.4rem; } }
  .main-about-us section p {
    font-family: "Conv_Poppins-Regular";
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 28px; }

.main-services {
  padding: 66px 0 57px 0;
  background: url(../images/content/main-services.jpg) no-repeat center;
  background-size: cover;
  position: relative; }
  .main-services:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #f2ca10;
    opacity: 0.9;
    z-index: 0; }
  .main-services h2 {
    font-family: "Conv_Poppins-SemiBold";
    font-size: 4.5rem;
    line-height: 5.4rem;
    color: #040707;
    position: absolute; }
    @media (max-width: 767px) {
      .main-services h2 {
        font-size: 3.5rem;
        line-height: 4.4rem; } }
  .main-services__wrap figure {
    height: 256px;
    width: 100%;
    margin-bottom: 12px;
    position: relative;
    overflow: hidden; }
    .main-services__wrap figure span {
      position: absolute;
      bottom: 20px;
      right: 20px;
      background: #000;
      display: inline-block;
      width: 44px;
      height: 44px;
      opacity: 0;
      -webkit-transition: .4s ease-in-out;
      -moz-transition: .4s ease-in-out;
      -ms-transition: .4s ease-in-out;
      -o-transition: .4s ease-in-out;
      transition: .4s ease-in-out; }
      .main-services__wrap figure span:before, .main-services__wrap figure span:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #f2ca10; }
      .main-services__wrap figure span:before {
        width: 20px;
        height: 2px; }
      .main-services__wrap figure span:after {
        width: 2px;
        height: 20px; }
  .main-services__wrap h3 {
    font-family: "Conv_Roboto-Bold";
    font-size: 1.7rem;
    line-height: 2.5rem;
    color: #040707; }
  .main-services__wrap:hover figure span {
    opacity: 1; }
  .main-services .owl-stage {
    padding-top: 83px; }
  .main-services .owl-nav {
    position: absolute;
    right: 0;
    top: 5px; }
    .main-services .owl-nav button {
      width: 37px;
      height: 37px;
      border: 1px solid #000 !important;
      -webkit-transition: .4s ease-in-out;
      -moz-transition: .4s ease-in-out;
      -ms-transition: .4s ease-in-out;
      -o-transition: .4s ease-in-out;
      transition: .4s ease-in-out; }
      .main-services .owl-nav button:before {
        content: "\e900";
        font-family: 'icomoon';
        font-size: 18px;
        color: #000;
        display: inline-block;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out; }
      .main-services .owl-nav button span {
        display: none; }
      .main-services .owl-nav button.owl-prev {
        margin-right: 4px; }
        .main-services .owl-nav button.owl-prev:before {
          transform: rotate(180deg); }
      .main-services .owl-nav button:hover {
        background: #000 !important; }
        .main-services .owl-nav button:hover:before {
          color: #f2ca10; }

.main-production-news {
  padding: 83px 0 93px 0; }
  @media (max-width: 991px) {
    .main-production-news {
      padding: 50px 0; } }
  .main-production-news h4 {
    font-family: "Conv_Poppins-Bold";
    font-size: 2.5rem;
    line-height: 3rem;
    color: #040707;
    margin-bottom: 20px; }
    @media (max-width: 767px) {
      .main-production-news h4 {
        font-size: 2rem;
        line-height: 2.6rem; } }
  .main-production-news__production {
    position: relative; }
    @media (max-width: 991px) {
      .main-production-news__production {
        margin-bottom: 40px; } }
    .main-production-news__production figure {
      height: 251px;
      width: 100%; }
      .main-production-news__production figure:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.7;
        z-index: 0; }
    .main-production-news__production .production__title {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      padding: 25px 20px; }
      .main-production-news__production .production__title h5 {
        font-family: "Conv_Poppins-Bold";
        font-size: 2rem;
        line-height: 2.8rem;
        color: #fff;
        position: absolute;
        left: 25px;
        bottom: 15px; }
        .main-production-news__production .production__title h5:before, .main-production-news__production .production__title h5:after {
          display: inline-block; }
        .main-production-news__production .production__title h5:before {
          content: "";
          position: absolute;
          bottom: 100%;
          margin-bottom: 15px;
          left: 0;
          width: 3px;
          height: 138px;
          background: #fff; }
        .main-production-news__production .production__title h5:after {
          content: "";
          position: absolute;
          bottom: 10px;
          width: 138px;
          height: 3px;
          background: #fff;
          left: 100%;
          margin-left: 15px; }
        .main-production-news__production .production__title h5 span {
          display: block; }
      .main-production-news__production .production__title p {
        font-family: "Conv_Poppins-Light";
        font-size: 1.5rem;
        line-height: 2rem;
        color: #fff;
        text-align: right; }
        .main-production-news__production .production__title p span {
          display: block; }
    .main-production-news__production .production-slide__control {
      position: absolute;
      right: 20px;
      bottom: 20px;
      width: 78px;
      height: 37px; }
      .main-production-news__production .production-slide__control a {
        width: 37px;
        height: 37px;
        border: 1px solid #fff !important;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out;
        opacity: 1; }
        .main-production-news__production .production-slide__control a:before {
          content: "\e900";
          font-family: 'icomoon';
          font-size: 18px;
          color: #fff;
          display: inline-block;
          -webkit-transition: .4s ease-in-out;
          -moz-transition: .4s ease-in-out;
          -ms-transition: .4s ease-in-out;
          -o-transition: .4s ease-in-out;
          transition: .4s ease-in-out; }
        .main-production-news__production .production-slide__control a span {
          display: none; }
        .main-production-news__production .production-slide__control a.carousel-control-prev {
          margin-right: 4px; }
          .main-production-news__production .production-slide__control a.carousel-control-prev:before {
            transform: rotate(180deg); }
        .main-production-news__production .production-slide__control a:hover {
          background: #fff !important; }
          .main-production-news__production .production-slide__control a:hover:before {
            color: #000; }
    .main-production-news__production .production__title h5 {
      opacity: 0;
      animation-duration: var(--animate-duration);
      animation-fill-mode: both; }
      .main-production-news__production .production__title h5:before, .main-production-news__production .production__title h5:after {
        animation-duration: var(--animate-duration);
        animation-fill-mode: both; }
    .main-production-news__production .production__title p {
      opacity: 0;
      animation-duration: var(--animate-duration);
      animation-fill-mode: both; }
    .main-production-news__production .carousel-item.active .production__title h5 {
      animation-name: fadeInDown; }
      .main-production-news__production .carousel-item.active .production__title h5:before {
        animation-name: beforeBorderAnimate;
        animation-delay: 0.5s; }
      .main-production-news__production .carousel-item.active .production__title h5:after {
        animation-name: afterBorderAnimate;
        animation-delay: 0.7s; }
    .main-production-news__production .carousel-item.active .production__title p {
      animation-name: fadeInUp; }
  .main-production-news__news {
    background: #ebebeb; }
    .main-production-news__news ul li:not(:last-child) {
      border-bottom: 1px solid #d9d4d4; }
    .main-production-news__news ul li a {
      padding: 26px 28px 26px 25px;
      display: flex; }
      .main-production-news__news ul li a div.date {
        margin-right: 17px;
        border: 1px solid #000;
        padding: 10px 6px; }
        .main-production-news__news ul li a div.date p {
          text-align: center;
          font-family: "Conv_Poppins-SemiBold";
          font-size: 0.9rem;
          line-height: 1.5rem;
          color: #040707; }
          .main-production-news__news ul li a div.date p span {
            display: block;
            font-size: 3.3rem;
            line-height: 3rem; }
      .main-production-news__news ul li a div h5 {
        font-family: "Conv_Poppins-SemiBold";
        font-size: 1.5rem;
        line-height: 2rem;
        color: #040707;
        margin-bottom: 8px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden; }
      .main-production-news__news ul li a div p {
        font-family: "Conv_Poppins-Light";
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #040707;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden; }
    .main-production-news__news .news-event-slide__control {
      position: absolute;
      right: 0;
      top: -50px;
      width: 78px;
      height: 37px; }
      @media (max-width: 991px) {
        .main-production-news__news .news-event-slide__control {
          right: 20px; } }
      .main-production-news__news .news-event-slide__control a {
        width: 37px;
        height: 37px;
        border: 1px solid #000 !important;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out;
        opacity: 1; }
        .main-production-news__news .news-event-slide__control a:before {
          content: "\e900";
          font-family: 'icomoon';
          font-size: 18px;
          color: #000;
          display: inline-block;
          -webkit-transition: .4s ease-in-out;
          -moz-transition: .4s ease-in-out;
          -ms-transition: .4s ease-in-out;
          -o-transition: .4s ease-in-out;
          transition: .4s ease-in-out; }
        .main-production-news__news .news-event-slide__control a span {
          display: none; }
        .main-production-news__news .news-event-slide__control a.carousel-control-prev {
          margin-right: 4px; }
          .main-production-news__news .news-event-slide__control a.carousel-control-prev:before {
            transform: rotate(180deg); }
        .main-production-news__news .news-event-slide__control a:hover {
          background: #000 !important; }
          .main-production-news__news .news-event-slide__control a:hover:before {
            color: #fff; }
    .main-production-news__news ul li {
      overflow: hidden; }
      .main-production-news__news ul li div {
        opacity: 0;
        animation-duration: var(--animate-duration);
        animation-fill-mode: both; }
    .main-production-news__news .carousel-item.active ul li div {
      animation-name: fadeInUp; }
      .main-production-news__news .carousel-item.active ul li div.date {
        animation-name: fadeInLeft; }
    .main-production-news__news .carousel-item.active ul li:last-child div {
      animation-delay: 0.3s; }

.main-facilities {
  padding: 59px 0 35px 0;
  background: url(../images/content/main-facilities.jpg) no-repeat center;
  background-size: cover;
  position: relative; }
  .main-facilities .row > div.col-lg-3 {
    margin-bottom: 78px; }
    @media (max-width: 991px) {
      .main-facilities .row > div.col-lg-3 {
        margin-bottom: 60px; } }
    @media (max-width: 767px) {
      .main-facilities .row > div.col-lg-3 {
        margin-bottom: 40px; } }
  .main-facilities h2 {
    font-family: "Conv_Poppins-SemiBold";
    font-size: 4.5rem;
    line-height: 5.4rem;
    margin-bottom: 80px;
    position: relative;
    color: #fff; }
    @media (max-width: 991px) {
      .main-facilities h2 {
        margin-bottom: 40px; } }
    @media (max-width: 767px) {
      .main-facilities h2 {
        font-size: 3.5rem;
        line-height: 4.4rem; } }
  .main-facilities__wrap {
    padding: 23px 0 23px 15px;
    border-left: 3px solid #fff;
    display: flex;
    align-items: flex-start;
    position: relative;
    height: 100%; }
    @media (max-width: 767px) {
      .main-facilities__wrap {
        flex-direction: column; } }
    .main-facilities__wrap:before, .main-facilities__wrap:after {
      content: "";
      position: absolute;
      left: 0;
      height: 3px;
      background: #fff; }
    .main-facilities__wrap:before {
      top: 0;
      animation-duration: var(--animate-duration);
      animation-fill-mode: both;
      animation: facilitiesBorderBefore 5s infinite; }
    .main-facilities__wrap:after {
      bottom: 0;
      width: 50%; }
    .main-facilities__wrap i {
      font-size: 50px;
      color: #f2ca10;
      margin-right: 15px; }
      @media (max-width: 767px) {
        .main-facilities__wrap i {
          margin-right: 0;
          margin-bottom: 20px; } }
    .main-facilities__wrap h6 {
      font-family: "Conv_Poppins-Bold";
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: #fff;
      margin-bottom: 9px; }
    .main-facilities__wrap p {
      font-family: "Conv_Poppins-Regular";
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: #fff; }

.sub-banner {
  height: 260px;
  width: 100%;
  position: relative;
  padding-top: 200px;
  overflow: hidden; }
  .sub-banner:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.8; }
  .sub-banner h2 {
    font-family: "Conv_Poppins-Light";
    font-size: 3.5rem;
    line-height: 4.2rem;
    color: #fff;
    position: relative;
    animation-duration: var(--animate-duration);
    animation-fill-mode: both;
    animation-name: fadeInUp; }
    .sub-banner h2 span {
      display: inline-block;
      font-family: "Conv_Poppins-SemiBold"; }

#map {
  width: 100%;
  height: 460px; }

.content-about-us {
  padding: 65px 0 32px 0; }
  @media (max-width: 991px) {
    .content-about-us {
      padding: 40px 0 30px 0; } }
  .content-about-us section {
    margin-bottom: 70px; }
    @media (max-width: 991px) {
      .content-about-us section {
        margin-bottom: 40px; } }
    .content-about-us section p {
      font-family: "Conv_Poppins-Regular";
      font-size: 1.4rem;
      line-height: 2rem;
      color: #040707; }
      .content-about-us section p b {
        font-family: "Conv_Poppins-SemiBold"; }
  .content-about-us h3 {
    font-family: "Conv_Poppins-Bold";
    font-size: 2rem;
    line-height: 2.8rem;
    color: #040707;
    margin-bottom: 20px; }
  @media (max-width: 991px) {
    .content-about-us ul {
      margin-bottom: 30px; } }
  .content-about-us ul li {
    font-family: "Conv_Poppins-Regular";
    font-size: 1.4rem;
    line-height: 2rem;
    color: #040707;
    position: relative;
    padding-left: 24px; }
    .content-about-us ul li:not(:last-child) {
      padding-bottom: 7px; }
    .content-about-us ul li:before {
      content: "";
      position: absolute;
      left: 5px;
      top: 7px;
      width: 6px;
      height: 6px;
      background: #f2ca10; }
  .content-about-us .ul_list {
    display: flex; }
    @media (max-width: 767px) {
      .content-about-us .ul_list {
        flex-direction: column; } }
    .content-about-us .ul_list ul:not(:last-child) {
      padding-right: 40px; }
    @media (max-width: 991px) {
      .content-about-us .ul_list ul {
        margin-bottom: 7px; } }

.black-bant {
  background: #000 url(../images/content/black-bant-banner.jpg) no-repeat center;
  background-size: cover;
  padding: 41px 0; }
  .black-bant ul {
    display: flex;
    align-items: center; }
    .black-bant ul li {
      text-align: center; }
      .black-bant ul li:not(:last-child) {
        padding-right: 98px; }
        @media (max-width: 576px) {
          .black-bant ul li:not(:last-child) {
            padding-right: 50px; } }
      .black-bant ul li i {
        font-size: 75px;
        color: #f2ca10;
        margin-bottom: 14px; }
      .black-bant ul li p {
        font-family: "Conv_Poppins-Regular";
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: #fff; }
        .black-bant ul li p span {
          display: block;
          font-family: "Conv_Poppins-SemiBold";
          font-size: 3rem;
          line-height: 3.8rem;
          color: #f2ca10;
          overflow: hidden; }
          .black-bant ul li p span b {
            font-weight: normal; }

.departments {
  padding: 54px 0 25px 0; }
  .departments .row > div {
    margin-bottom: 36px; }
  .departments__wrap {
    height: 100%;
    background: #f7f7f7; }
    .departments__wrap figure {
      width: 100%;
      height: 212px;
      position: relative; }
      .departments__wrap figure:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 70%;
        height: 5px;
        background: #f2ca10;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out; }
    .departments__wrap section {
      padding: 24px 22px; }
      .departments__wrap section h5 {
        font-family: "Conv_Poppins-SemiBold";
        font-size: 2rem;
        line-height: 2.8rem;
        color: #040707;
        margin-bottom: 12px; }
        .departments__wrap section h5 span {
          display: inline-block;
          font-family: "Conv_Poppins-Light"; }
      .departments__wrap section p {
        font-family: "Conv_Poppins-Light";
        font-size: 1.2rem;
        line-height: 1.4rem;
        color: #7a7a7a;
        position: relative;
        padding-left: 15px; }
        .departments__wrap section p:before {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4px 0 4px 8px;
          border-color: transparent transparent transparent #f2ca10;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -4px; }
    .departments__wrap a {
      display: block;
      height: 100%; }
    .departments__wrap:hover figure:before {
      width: 40%; }

.departments-detail {
  padding: 98px 0 75px 0;
  position: relative; }
  .departments-detail:before {
    content: "";
    position: absolute;
    right: 0;
    width: 30%;
    height: 1px;
    background: #f7f7f7;
    top: 98px; }
    @media (max-width: 991px) {
      .departments-detail:before {
        content: none; } }
  .departments-detail .container {
    position: relative; }
    .departments-detail .container:before {
      content: "";
      position: absolute;
      right: 15%;
      top: -98px;
      width: 460px;
      height: 187px;
      background: #f7f7f7; }
      @media (max-width: 991px) {
        .departments-detail .container:before {
          right: auto;
          left: 0;
          width: 60%; } }
  .departments-detail a.turn-back {
    font-family: "Conv_Poppins-SemiBold";
    font-size: 1.2rem;
    line-height: 2rem;
    color: #040707;
    display: inline-block;
    text-align: center;
    position: absolute;
    right: 15px;
    top: -65px; }
    .departments-detail a.turn-back span {
      display: block; }
    .departments-detail a.turn-back i {
      display: inline-block;
      font-size: 21px;
      color: #f2ca10;
      margin-bottom: 6px;
      transform: rotate(180deg); }
  .departments-detail figure {
    width: 100%;
    height: 363px;
    position: relative; }
    .departments-detail figure:before {
      content: "";
      position: absolute;
      left: -30px;
      bottom: -30px;
      width: 320px;
      height: 252px;
      background: #f2ca10;
      z-index: -1; }
      @media (max-width: 991px) {
        .departments-detail figure:before {
          left: -15px;
          bottom: -15px; } }
  .departments-detail section {
    padding-left: 13px; }
    @media (max-width: 991px) {
      .departments-detail section {
        padding-left: 0;
        margin-top: 40px; } }
    .departments-detail section h3 {
      font-family: "Conv_Poppins-SemiBold";
      font-size: 3.5rem;
      line-height: 4.4rem;
      color: #040707;
      margin-bottom: 70px; }
      @media (max-width: 991px) {
        .departments-detail section h3 {
          margin-bottom: 20px; } }
      .departments-detail section h3 span {
        display: inline-block;
        font-family: "Conv_Poppins-Light"; }
    .departments-detail section p {
      font-family: "Conv_Poppins-Light";
      font-size: 1.2rem;
      line-height: 2.1rem;
      color: #040707; }

.accordion-page {
  padding: 50px 0; }
  .accordion-page .accordion .card {
    border-bottom: 1px solid #dfdfdf; }
    .accordion-page .accordion .card:not(:last-child) {
      margin-bottom: 13px; }
    .accordion-page .accordion .card .card-header {
      padding: 0; }
      .accordion-page .accordion .card .card-header button {
        font-family: "Conv_Roboto-Regular";
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #2d2d2d;
        background: #f7f7f7;
        padding: 17px 200px 17px 28px;
        width: 100%;
        text-align: left;
        text-decoration: none;
        outline: none;
        box-shadow: 0 0 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative; }
        @media (max-width: 991px) {
          .accordion-page .accordion .card .card-header button {
            padding-right: 80px;
            padding-top: 50px;
            padding-left: 15px; } }
        .accordion-page .accordion .card .card-header button:before {
          content: "\e900";
          font-family: 'icomoon';
          font-size: 22px;
          color: #f2ca10;
          transform: rotate(90deg);
          right: 25px;
          top: 50%;
          position: absolute;
          margin-top: -11px; }
        .accordion-page .accordion .card .card-header button:after {
          content: "";
          position: absolute;
          top: 0;
          right: 65px;
          height: 100%;
          width: 1px;
          background: #dfdfdf; }
        .accordion-page .accordion .card .card-header button span.date {
          font-family: "Conv_Roboto-Regular";
          font-size: 1.3rem;
          line-height: 2rem;
          color: #2d2d2d;
          position: absolute;
          right: 90px; }
          @media (max-width: 991px) {
            .accordion-page .accordion .card .card-header button span.date {
              right: auto;
              left: 15px;
              top: 15px; } }
          .accordion-page .accordion .card .card-header button span.date i {
            font-size: 23px;
            color: #f2ca10;
            margin-right: 7px; }
    .accordion-page .accordion .card .card-body {
      padding: 31px 30px 42px 30px;
      font-family: "Conv_Roboto-Light";
      font-size: 1.3rem;
      line-height: 2rem;
      color: #2d2d2d; }
      @media (max-width: 991px) {
        .accordion-page .accordion .card .card-body {
          padding: 30px 15px; } }
  .accordion-page.test-results .accordion .card {
    border: none; }
    .accordion-page.test-results .accordion .card .card-header {
      border: 1px solid #dfdfdf;
      margin-bottom: 0; }
      @media (max-width: 991px) {
        .accordion-page.test-results .accordion .card .card-header button {
          padding-top: 17px; } }
      .accordion-page.test-results .accordion .card .card-header span.date {
        position: initial;
        font-family: "Conv_Roboto-Medium";
        font-size: 1.6rem;
        line-height: 2.4rem; }
        .accordion-page.test-results .accordion .card .card-header span.date i {
          margin-right: 8px; }
    .accordion-page.test-results .accordion .card .card-body {
      padding: 15px 0; }
      .accordion-page.test-results .accordion .card .card-body img {
        max-width: 100%;
        width: 100%; }

.contact-us {
  padding: 70px 0; }
  .contact-us__information {
    padding: 0 40px; }
    @media (max-width: 991px) {
      .contact-us__information {
        padding: 0;
        margin-bottom: 50px; } }
    .contact-us__information h2 {
      font-family: "Conv_Poppins-SemiBold";
      font-size: 5rem;
      line-height: 6rem;
      color: #e4e4e4;
      margin-bottom: 30px;
      opacity: 0;
      animation-duration: var(--animate-duration);
      animation-fill-mode: both;
      animation-name: fadeInDown; }
      @media (max-width: 767px) {
        .contact-us__information h2 {
          font-size: 3.5rem;
          line-height: 4.2rem; } }
      .contact-us__information h2 span {
        font-family: "Conv_Poppins-Light"; }
    .contact-us__information p {
      font-family: "Conv_Roboto-Regular";
      font-size: 1.4rem;
      line-height: 2.1rem;
      color: #505050;
      margin-bottom: 32px; }
    .contact-us__information ul li:not(:last-child) {
      margin-bottom: 14px; }
    .contact-us__information ul li a {
      font-family: "Conv_Roboto-Regular";
      font-size: 1.4rem;
      line-height: 2.1rem;
      color: #505050; }
      .contact-us__information ul li a i {
        font-size: 23px;
        color: #f2ca10;
        margin-right: 8px; }
  .contact-us__form {
    margin-right: 70px;
    max-width: 428px; }
    @media (max-width: 991px) {
      .contact-us__form {
        margin: 0;
        max-width: 100%; } }
    .contact-us__form_input {
      margin-bottom: 42px; }
      .contact-us__form_input input, .contact-us__form_input textarea {
        width: 100%;
        font-family: "Conv_Poppins-Light";
        font-size: 1.5rem;
        line-height: 2rem;
        color: #040707;
        padding: 8px 0;
        border-bottom: 1px solid #2d2d2d;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out; }
        .contact-us__form_input input::-webkit-input-placeholder, .contact-us__form_input textarea::-webkit-input-placeholder {
          color: #040707;
          -webkit-transition: .4s ease-in-out;
          -moz-transition: .4s ease-in-out;
          -ms-transition: .4s ease-in-out;
          -o-transition: .4s ease-in-out;
          transition: .4s ease-in-out; }
        .contact-us__form_input input:-moz-placeholder, .contact-us__form_input textarea:-moz-placeholder {
          color: #040707;
          -webkit-transition: .4s ease-in-out;
          -moz-transition: .4s ease-in-out;
          -ms-transition: .4s ease-in-out;
          -o-transition: .4s ease-in-out;
          transition: .4s ease-in-out; }
        .contact-us__form_input input::-moz-placeholder, .contact-us__form_input textarea::-moz-placeholder {
          color: #040707;
          -webkit-transition: .4s ease-in-out;
          -moz-transition: .4s ease-in-out;
          -ms-transition: .4s ease-in-out;
          -o-transition: .4s ease-in-out;
          transition: .4s ease-in-out; }
        .contact-us__form_input input:-ms-input-placeholder, .contact-us__form_input textarea:-ms-input-placeholder {
          color: #040707;
          -webkit-transition: .4s ease-in-out;
          -moz-transition: .4s ease-in-out;
          -ms-transition: .4s ease-in-out;
          -o-transition: .4s ease-in-out;
          transition: .4s ease-in-out; }
        .contact-us__form_input input:focus, .contact-us__form_input textarea:focus {
          border-bottom: 1px solid #f2ca10; }
          .contact-us__form_input input:focus::-webkit-input-placeholder, .contact-us__form_input textarea:focus::-webkit-input-placeholder {
            color: #f2ca10; }
          .contact-us__form_input input:focus:-moz-placeholder, .contact-us__form_input textarea:focus:-moz-placeholder {
            color: #f2ca10; }
          .contact-us__form_input input:focus::-moz-placeholder, .contact-us__form_input textarea:focus::-moz-placeholder {
            color: #f2ca10; }
          .contact-us__form_input input:focus:-ms-input-placeholder, .contact-us__form_input textarea:focus:-ms-input-placeholder {
            color: #f2ca10; }
      .contact-us__form_input textarea {
        resize: none;
        height: 37px; }
    .contact-us__form_button button {
      font-family: "Conv_Poppins-Regular";
      font-size: 2rem;
      line-height: 3rem;
      color: #f2ca10;
      width: 100%;
      border: 1px solid #f2ca10;
      padding: 17px 15px;
      background: rgba(242, 202, 16, 0.15);
      border-radius: 4px; }
      .contact-us__form_button button i {
        font-size: 25px;
        margin-right: 10px; }

.content-gallery {
  padding: 40px 0; }
  .content-gallery ul.nav {
    margin-bottom: 50px; }
    .content-gallery ul.nav li:not(:last-child) {
      padding-right: 10px; }
      @media (max-width: 576px) {
        .content-gallery ul.nav li:not(:last-child) {
          padding-right: 5px; } }
    @media (max-width: 576px) {
      .content-gallery ul.nav li {
        width: 33.33333%; } }
    .content-gallery ul.nav li a {
      font-family: "Conv_Poppins-Regular";
      font-size: 1.6rem;
      line-height: 2rem;
      color: #040707;
      border: 2px solid #000;
      border-radius: 0;
      padding: 8px 25px; }
      @media (max-width: 576px) {
        .content-gallery ul.nav li a {
          padding: 8px 5px;
          font-size: 1.4rem;
          line-height: 1.8rem;
          text-align: center; } }
      .content-gallery ul.nav li a.active {
        background: none;
        color: #f2ca10;
        border-color: #f2ca10; }
  .content-gallery .tab-pane .container .row > div {
    margin-bottom: 30px; }
  .content-gallery .gallery-card {
    padding: 15px;
    border: 1px solid #bfbfbf;
    position: relative;
    height: 100%;
    -webkit-transition: .4s ease-in-out;
    -moz-transition: .4s ease-in-out;
    -ms-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out; }
    .content-gallery .gallery-card figure {
      width: 100%;
      height: 220px;
      position: relative;
      /*&:before,&:after{
				content:"";
				position: absolute;
				left: 50%;
				top: 50%;
				background: $white;
				display: inline-block;
				transform: translate(-50%,-50%);
				opacity: 0;
				@include transition;
				z-index: 2;
			}
			&:before{
				width: 3px;
				height: 30px;
			}
			&:after{
				width: 30px;
				height: 3px;
			}*/ }
      @media (max-width: 1200px) {
        .content-gallery .gallery-card figure {
          height: 190px; } }
      .content-gallery .gallery-card figure:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #f2ca10;
        opacity: 0;
        z-index: 1;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out; }
      .content-gallery .gallery-card figure span {
        position: absolute;
        left: 50%;
        top: 50%;
        display: inline-block;
        width: 30px;
        height: 30px;
        transform: translate(-50%, -50%);
        z-index: 2;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out;
        opacity: 0; }
        .content-gallery .gallery-card figure span:before, .content-gallery .gallery-card figure span:after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          background: #fff;
          display: inline-block;
          transform: translate(-50%, -50%); }
        .content-gallery .gallery-card figure span:before {
          width: 3px;
          height: 30px; }
        .content-gallery .gallery-card figure span:after {
          width: 30px;
          height: 3px; }
    .content-gallery .gallery-card img {
      max-width: 100%;
      max-height: 300px; }
    .content-gallery .gallery-card:hover {
      border-color: #f2ca10; }
      .content-gallery .gallery-card:hover figure:before {
        opacity: 0.6; }
      .content-gallery .gallery-card:hover figure span {
        opacity: 1; }
