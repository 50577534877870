.departments-detail{
	padding: 98px 0 75px 0;
	position: relative;
	&:before{
		content:"";
		position: absolute;
		right: 0;
		width: 30%;
		height:1px;
		background: $lightgrey;
		top: 98px;
		@media (max-width: $md-width){
			content:none;
		}
	}
	.container{
		position: relative;
		&:before{
			content:"";
			position: absolute;
			right: 15%;
			top: -98px;
			width: 460px;
			height: 187px;
			background: $lightgrey;
			@media (max-width: $md-width){
				right: auto;
				left: 0;
				width: 60%;
			}
		}
	}
	a{
		&.turn-back{
			font-family: $poppinssemibold;
			@include font-size(12,20);
			color:$fontblack;
			display: inline-block;
			text-align: center;
			position: absolute;
			right: 15px;
			top: -65px;
			span{
				display: block;
			}
			i{
				display: inline-block;
				font-size: 21px;
				color:$yellow;
				margin-bottom: 6px;
				transform: rotate(180deg);
			}
		}
	}
	figure{
		width: 100%;
		height: 363px;
		position: relative;
		&:before{
			content:"";
			position: absolute;
			left: -30px;
			bottom: -30px;
			width: 320px;
			height: 252px;
			background: $yellow;
			z-index: -1;
			@media (max-width: $md-width){
				left: -15px;
				bottom: -15px;
			}
		}
	}
	section{
		padding-left: 13px;
		@media (max-width: $md-width){
			padding-left: 0;
			margin-top: 40px;
		}
		h3{
			font-family: $poppinssemibold;
			@include font-size(35,44);
			color:$fontblack;
			margin-bottom: 70px;
			@media (max-width: $md-width){
				margin-bottom: 20px;
			}
			span{
				display: inline-block;
				font-family: $poppinslight;
			}
		}
		p{
			font-family: $poppinslight;
			@include font-size(12,21);
			color:$fontblack;
		}
	}
}