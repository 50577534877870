.content-gallery{
	padding: 40px 0;
	ul{
		&.nav{
			margin-bottom: 50px;
			li{
				&:not(:last-child){
					padding-right: 10px;
					@media (max-width: $xs-width){
						padding-right: 5px;
					}
				}
				@media (max-width: $xs-width){
					width: 33.33333%;
				}
				a{
					font-family: $poppinsregular;
					@include font-size(16,20);
					color:$fontblack;
					border:2px solid $black;
					border-radius: 0;
					padding: 8px 25px;
					@media (max-width: $xs-width){
						padding:8px 5px;
						@include font-size(14,18);
						text-align: center;
					}
					&.active{
						background: none;
						color:$yellow;
						border-color:$yellow;
					}
				}
			}
		}
	}
	.tab-pane{
		.container{
			.row{
				> div{
					margin-bottom: 30px;
				}
			}
		}
	}
	.gallery-card{
		padding: 15px;
		border:1px solid #bfbfbf;
		position: relative;
		height: 100%;
		@include transition;
		figure{
			width: 100%;
			height: 220px;
			position: relative;
			@media (max-width: $lg-width){
				height: 190px;
			}
			&:before{
				content:"";
				position: absolute;
				left:0;
				top: 0;
				width: 100%;
				height: 100%;
				background: $yellow;
				opacity: 0;
				z-index: 1;
				@include transition;
			}
			span{
				position: absolute;
				left: 50%;
				top: 50%;
				display: inline-block;
				width: 30px;
				height: 30px;
				transform: translate(-50%,-50%);
				z-index: 2;
				@include transition;
				opacity: 0;
				&:before,&:after{
					content:"";
					position: absolute;
					left: 50%;
					top: 50%;
					background: $white;
					display: inline-block;
					transform: translate(-50%,-50%);
				}
				&:before{
					width: 3px;
					height: 30px;
				}
				&:after{
					width: 30px;
					height: 3px;
				}
			}
			/*&:before,&:after{
				content:"";
				position: absolute;
				left: 50%;
				top: 50%;
				background: $white;
				display: inline-block;
				transform: translate(-50%,-50%);
				opacity: 0;
				@include transition;
				z-index: 2;
			}
			&:before{
				width: 3px;
				height: 30px;
			}
			&:after{
				width: 30px;
				height: 3px;
			}*/
		}
		img{
			max-width: 100%;
			max-height: 300px;
		}
		&:hover{
			border-color:$yellow;
			figure{
				&:before{
					opacity:0.6;
				}
				span{
					opacity: 1
				}
			}
		}
	}
}