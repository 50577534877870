@font-face {
    font-family: 'Conv_Poppins-Bold';
    src: url('#{$fonts}/Poppins-Bold.eot');
    src: local('☺'), url('#{$fonts}/Poppins-Bold.woff') format('woff'), url('#{$fonts}/Poppins-Bold.ttf') format('truetype'), url('#{$fonts}/Poppins-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_Poppins-Light';
    src: url('#{$fonts}/Poppins-Light.eot');
    src: local('☺'), url('#{$fonts}/Poppins-Light.woff') format('woff'), url('#{$fonts}/Poppins-Light.ttf') format('truetype'), url('#{$fonts}/Poppins-Light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_Poppins-Medium';
    src: url('#{$fonts}/Poppins-Medium.eot');
    src: local('☺'), url('#{$fonts}/Poppins-Medium.woff') format('woff'), url('#{$fonts}/Poppins-Medium.ttf') format('truetype'), url('#{$fonts}/Poppins-Medium.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_Poppins-Regular';
    src: url('#{$fonts}/Poppins-Regular.eot');
    src: local('☺'), url('#{$fonts}/Poppins-Regular.woff') format('woff'), url('#{$fonts}/Poppins-Regular.ttf') format('truetype'), url('#{$fonts}/Poppins-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_Poppins-SemiBold';
    src: url('#{$fonts}/Poppins-SemiBold.eot');
    src: local('☺'), url('#{$fonts}/Poppins-SemiBold.woff') format('woff'), url('#{$fonts}/Poppins-SemiBold.ttf') format('truetype'), url('#{$fonts}/Poppins-SemiBold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_Blanka-Regular';
    src: url('#{$fonts}/Blanka-Regular.eot');
    src: local('☺'), url('#{$fonts}/Blanka-Regular.woff') format('woff'), url('#{$fonts}/Blanka-Regular.ttf') format('truetype'), url('#{$fonts}/Blanka-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_Roboto-Bold';
    src: url('#{$fonts}/Roboto-Bold.eot');
    src: local('☺'), url('#{$fonts}/Roboto-Bold.woff') format('woff'), url('#{$fonts}/Roboto-Bold.ttf') format('truetype'), url('#{$fonts}/Roboto-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_Roboto-Medium';
    src: url('#{$fonts}/Roboto-Medium.eot');
    src: local('☺'), url('#{$fonts}/Roboto-Medium.woff') format('woff'), url('#{$fonts}/Roboto-Medium.ttf') format('truetype'), url('#{$fonts}/Roboto-Medium.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_Roboto-Regular';
    src: url('#{$fonts}/Roboto-Regular.eot');
    src: local('☺'), url('#{$fonts}/Roboto-Regular.woff') format('woff'), url('#{$fonts}/Roboto-Regular.ttf') format('truetype'), url('#{$fonts}/Roboto-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_Roboto-Light';
    src: url('#{$fonts}/Roboto-Light.eot');
    src: local('☺'), url('#{$fonts}/Roboto-Light.woff') format('woff'), url('#{$fonts}/Roboto-Light.ttf') format('truetype'), url('#{$fonts}/Roboto-Light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'icomoon';
    src:  url('#{$fonts}/icomoon.eot?as1b3k');
    src:  url('#{$fonts}/icomoon.eot?as1b3k#iefix') format('embedded-opentype'),
        url('#{$fonts}/icomoon.ttf?as1b3k') format('truetype'),
        url('#{$fonts}/icomoon.woff?as1b3k') format('woff'),
        url('#{$fonts}/icomoon.svg?as1b3k#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}