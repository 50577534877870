.main-services{
	padding: 66px 0 57px 0;
	background: url(#{$images}/content/main-services.jpg) no-repeat center;
	background-size: cover;
	position: relative;
	&:before{
		content:"";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #f2ca10;
		opacity: 0.9;
		z-index: 0;
	}
	h2{
		font-family: $poppinssemibold;
		@include font-size(45,54);
		color:$fontblack;
		position: absolute;
		@media (max-width: $sm-width){
			@include font-size(35,44);
		}
	}
	&__wrap{
		figure{
			height: 256px;
			width: 100%;
			margin-bottom: 12px;
			position: relative;
			overflow: hidden;
			span{
				position: absolute;
				bottom: 20px;
				right: 20px;
				background: $black;
				display: inline-block;
				width: 44px;
				height: 44px;
				opacity: 0;
				@include transition;
				&:before,&:after{
					content:"";
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
					background: $yellow;
				}
				&:before{
					width: 20px;
					height: 2px;
				}
				&:after{
					width: 2px;
					height: 20px;
				}
			}
		}
		h3{
			font-family: $robotobold;
			@include font-size(17,25);
			color:$fontblack;
		}
		&:hover{
			figure{
				span{
					opacity: 1;
				}
			}
		}
	}
	.owl-stage{
		padding-top: 83px;
	}
	.owl-nav{
	    position: absolute;
	    right: 0;
	    top: 5px;
	    button{
	    	width: 37px;
	    	height: 37px;
	    	border:1px solid $black !important;
	    	@include transition;
	    	&:before{
	    		content:"\e900";
	    		font-family: 'icomoon';
	    		font-size: 18px;
	    		color:$black;
	    		display: inline-block;
	    		@include transition;
	    	}
	    	span{
	    		display: none;		
	    	}
	    	&.owl-prev{
	    		margin-right: 4px;
	    		&:before{
	    			transform:rotate(180deg);
	    		}
	    	}
	    	&:hover{
	    		background:$black !important;
	    		&:before{
	    			color:$yellow;
	    		}
	    	}
	    }
	}
}