.main-about-us{
	.container{
		background: url(#{$images}/content/main-about-us.jpg) no-repeat left top;
		@media (max-width: $md-width){
			background-size: auto;
			background-position: left 0px top -30px;
		}
	}
	section{
		padding: 93px 0;
		@media (max-width: $md-width){
			padding:40px 0;
			margin-top: 320px;
		}
		h2,p{
			color:$fontblack;
		}
		h2{
			font-family: $poppinssemibold;
			@include font-size(45,54);
			margin-bottom: 32px;
			@media (max-width: $sm-width){
				@include font-size(35,44);
			}
		}
		p{
			font-family: $poppinsregular;
			@include font-size(15,20);
			margin-bottom: 28px;
		}
	}
}