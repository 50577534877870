@keyframes spanColor {
	from {
		width: 0;
	}
	to {
		width: 60%;
	}
}

@keyframes beforeBorderAnimate {
	from {
		height: 0;
	}
	to {
		height: 138px;
	}
}

@keyframes afterBorderAnimate {
	from {
		width: 0;
	}
	to {
		width: 138px;
	}
}

@keyframes facilitiesBorderBefore {
	0% {
		width: 70%;
	}
	25% {
		width: 40%;
	}
	50% {
		width: 50%;
	}
	100% {
		width: 70%;
	}
}


@media (max-width: $sm-width){
	@keyframes beforeBorderAnimate {
		from {
			height: 0;
		}
		to {
			height: 40px;
		}
	}
	@keyframes afterBorderAnimate {
		from {
			width: 0;
		}
		to {
			width: 40px;
		}
	}
}
@media (max-width: $xs-width){
	@keyframes afterBorderAnimate {
		from {
			width: 0;
		}
		to {
			width: 20px;
		}
	}
}
