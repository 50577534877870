header{
	padding: 30px 0 0 0;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 999;
	@media (max-width: $md-width){
	    padding: 20px 0 25px 0;
		background: rgba(0,0,0,0.8);
	}
	nav{
		margin:0;
		padding: 0 !important;
		align-items: flex-start !important;
		.navbar-brand{
			img{
				max-width: 66px;
				width: 100%;
				@media (max-width: $md-width){
				    position: absolute;
				    max-width: 45px;
				}
			}
		}
		.navbar-collapse{
			justify-content: space-around;
		}
		button{
			&.navbar-toggler{
				padding: 0;
				margin:0;
				height: 32px;
				width: 35px;
				span.navbar-toggler-icon{
					width: 32px;
				    height: 2.5px;
				    background: $white;
				    position: relative;
				    &:before,&:after{
				    	content:"";
				    	width: 32px;
					    height: 2.5px;
					    background: $white;
					    position: absolute;
					    display: inline-block;
					    left: 0;
				    }
				    &:before{
				    	top: -10px;
				    }
				    &:after{
				    	bottom: -10px;
				    }
				}
			}
		}
		ul{
			display: flex;
			padding-top: 25px;
			@media (max-width: $md-width){
				padding-top: 75px;
			}
			li{
				padding:0 12.5px;
				&:last-child{
					padding-right:0;
				}
				@media (max-width: $md-width){
					padding:4px 0;
				}
				a{
					font-family: $robotobold;
					@include font-size(12,16);
					color:$white;
					@include transition;
					&:hover{
						color:$yellow;
					}
				}
				&.active{
					a{
						color:$yellow;
					}
				}
				&.xs-visible{
					display: none;
					@media (max-width: $md-width){
						display: block;
					}
				}
			}
			&.navbar-nav{
				margin:0 auto;
			}
			&.nav-right{
				@media (max-width: $md-width){
					display: none;
				}
				li{
					a{
						@include font-size(14,21);
						i{
							font-size: 28px;
							color:$yellow;
							margin-right:9px;
						}
					}
				}
			}
		}
	}
}