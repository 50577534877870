.main-facilities{
	padding: 59px 0 35px 0;
	background: url(#{$images}/content/main-facilities.jpg) no-repeat center;
	background-size: cover;
	position: relative;
	.row{
		> div{
			&.col-lg-3{
				margin-bottom: 78px;
				@media (max-width: $md-width){
					margin-bottom: 60px;
				}
				@media (max-width: $sm-width){
					margin-bottom:40px;
				}
			}
		}
	}
	h2{
		font-family: $poppinssemibold;
		@include font-size(45,54);
		margin-bottom: 80px;
		position: relative;
		color:$white;
		@media (max-width: $md-width){
			margin-bottom: 40px;
		}
		@media (max-width: $sm-width){
			@include font-size(35,44);
		}
	}
	&__wrap{
		padding: 23px 0 23px 15px;
		border-left: 3px solid $white;
		display: flex;
		align-items: flex-start;
		position: relative;
		height: 100%;
		@media (max-width: $sm-width){
			flex-direction: column;
		}
		&:before,&:after{
			content:"";
			position: absolute;
			left: 0;
			height: 3px;
			background: $white;
		}
		&:before{
			top: 0;
			animation-duration: var(--animate-duration);
			animation-fill-mode: both;
			animation: facilitiesBorderBefore 5s infinite;
		}
		&:after{
			bottom: 0;
			width: 50%;
		}
		i{
			font-size: 50px;
			color:$yellow;
			margin-right: 15px;
			@media (max-width: $sm-width){
				margin-right: 0;
				margin-bottom: 20px;
			}
		}
		h6{
			font-family: $poppinsbold;
			@include font-size(16,22);
			color:$white;
			margin-bottom: 9px;
		}
		p{
			font-family: $poppinsregular;
			@include font-size(12,18);
			color:$white;
		}
	}
}