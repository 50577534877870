.black-bant{
	background: $black url(#{$images}/content/black-bant-banner.jpg) no-repeat center;
	background-size: cover;
	padding: 41px 0;
	ul{
		display: flex;
		align-items: center;
		li{
			text-align: center;
			&:not(:last-child){
				padding-right: 98px;
				@media (max-width: $xs-width){
					padding-right: 50px;
				}
			}
			i{
				font-size: 75px;
				color:$yellow;
				margin-bottom: 14px;
			}
			p{
				font-family: $poppinsregular;
				@include font-size(12,16);
				color:$white;
				span{
					display: block;
					font-family: $poppinssemibold;
					@include font-size(30,38);
					color:$yellow;
					overflow: hidden;
					b{
						font-weight: normal;
					}
				}
			}
		}
	}
}