.main-hero{
	position: relative;
	&:before{
		content:"";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: $black;
		opacity: 0.8;
		z-index: 1;
	}
	figure{
		height: 718px;
		width: 100%;
		@media (max-width: $md-width){
			height: 550px;
		}
	}
	&__title{
	    position: absolute;
	    top: 50%;
	    left: 0;
	    width: 100%;
	    transform: translate(0, -50%);
	    z-index: 10;
	    h1,p{
	    	max-width: 330px;
	    }
	    h1{
	    	font-family: $poppinssemibold;
	    	@include font-size(45,54);
	    	color:$white;
	    	letter-spacing: 1.48px;
	    	margin-bottom: 15px;
	    	@media (max-width: $md-width){
	    		@include font-size(35,44);
	    	}
	    	span{
	    		display:inline-block;
	    		position: relative;
	    		padding-right: 5px;
	    		&:nth-child(2){
	    			&:before{
	    				content:"";
	    				position: absolute;
	    				display: inline-block;
	    				right: 0;
	    				top: 0;
	    				background: $yellow;
	    				height: 100%;
	    				z-index: -1;
	    			}
	    		}
	    	}
	    }
	    p{
	    	font-family: $poppinslight;
	    	@include font-size(16,24);
	    	color:$white;
    	    display: -webkit-box;
		    -webkit-box-orient: vertical;
		    -webkit-line-clamp: 2;
		    overflow: hidden;
		    margin-bottom: 30px;
	    }
	}
	ol{
		&.carousel-indicators{
		    padding: 0 15px;
		    width: 100%;
		    margin: 0 auto;
		    justify-content: flex-start;
		    bottom: 35px;
		    li{
		    	width: 36px;
		    	height: 36px;
		    	text-indent: 0;
		    	font-family: $poppinsmedium;
		    	@include font-size(14,21);
		    	color:$white;
		    	background: none;
		    	border:none;
		    	text-align: center;
		    	opacity: 1;
		    	margin:0;
		    	line-height: 2;
		    	span{
		    		position: relative;
		    		display: inline-block;
		    		padding: 5px;
		    		width: 37px;
		    		height: 37px;
		    		@include transition;
		    		&:before,&:after{
		    			content: "";
					    width: 100%;
					    height: 100%;
					    border: 2px solid $yellow;
					    border-radius: 50%;
					    top: 50%;
					    left: 50%;
		    			opacity:0;
		    			display: inline-block;
		    			transform:translate(-50%,-50%) rotate(-45deg);
		    			@include transition_e(1s ease-in-out);
		    		}
		    		&:before{
					    position: absolute;
					    border-bottom-color: transparent;
					    border-left-color: transparent;
			    	}
			    	&:after{
					    position: absolute;
					    top: 50%;
					    left: 50%;
					    border-radius: 50%;
					    border-top-color: transparent;
					    border-right-color: transparent;
					    border-bottom-color: transparent;
			    	}
		    	}
		    	&:not(:last-child){
		    		padding-right: 30px;
		    		@media (max-width: $md-width){
			    		padding-right: 20px;
			    	}
		    	}
		    	&:hover{
		    		color:$yellow;
		    	}
		    	&.active{
		    		color:$yellow;
		    		span{
		    			&:before,&:after{
		    				opacity:1;
		    				transform:translate(-50%,-50%) rotate(300deg);
		    			}
		    		}
		    	}
		    }
		}
	}
	&__phone{
	    position: absolute;
	    bottom: 35px;
	    z-index: 1;
	    left: 0;
	    width: 100%;
	    margin: 0 auto;
	    right: 0;
	    @media (max-width: $sm-width){
	    	display: none;
	    }
	    p{
	    	font-family: $poppinssemibold;
	    	@include font-size(16,24);
	    	color:$white;
	    	text-align: right;
	    	span{
	    		display: block;
	    		font-family: $poppinslight;
	    		color:$yellow;
	    	}
	    	a{
	    		color:$white;
	    	}
	    }
	}
	.carousel-inner{
		h1 span,p,a{
			animation-duration: var(--animate-duration);
			animation-fill-mode: both;
		}
		h1{
			span{
				opacity: 0;
				&:before{
					animation-duration: var(--animate-duration);
					animation-fill-mode: both;
				}
			}
		}
		p{
			opacity: 0;
		}
		a{
			opacity: 0;
		}
	}
	.carousel-item{
		&.active{
			h1{
				span{
					animation-name: fadeInLeft;
					&:nth-child(1){
						animation-delay: 0.4s;
					}
					&:nth-child(2){
						animation-delay: 0.7s;
					}
					&:nth-child(3){
						animation-delay: 1s;
					}
					&:before{
						animation-name: spanColor;
						animation-delay: 0.7s;
					}
				}
			}
			p{
				animation-name: fadeInUp;
				animation-delay: 1s;
			}
			a{
				animation-name: fadeInUp;
				animation-delay: 1.3s;
			}
		}
	}
}

