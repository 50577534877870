.contact-us{
	padding: 70px 0;
	&__information{
		padding: 0 40px;
		@media (max-width: $md-width){
			padding: 0;
			margin-bottom: 50px;
		}
		h2{
			font-family: $poppinssemibold;
			@include font-size(50,60);
			color:#e4e4e4;
			margin-bottom: 30px;
			opacity: 0;
			animation-duration: var(--animate-duration);
			animation-fill-mode: both;
			animation-name: fadeInDown;
			@media (max-width: $sm-width){
				@include font-size(35,42);
			}
			span{
				font-family: $poppinslight;
			}
		}
		p{
			font-family: $robotoregular;
			@include font-size(14,21);
			color:$contactfont;
			margin-bottom: 32px;
		}
		ul{
			li{
				&:not(:last-child){
					margin-bottom: 14px;
				}
				a{
					font-family: $robotoregular;
					@include font-size(14,21);
					color:$contactfont;
					i{
						font-size: 23px;
						color:$yellow;
						margin-right: 8px;
					}
				}
			}
		}
	}
	&__form{
		margin-right: 70px;
		max-width: 428px;
		@media (max-width: $md-width){
			margin:0;
			max-width: 100%;
		}
		&_input{
			margin-bottom: 42px;
			input,textarea{
				width: 100%;
				font-family: $poppinslight;
				@include font-size(15,20);
				color:$fontblack;
				padding: 8px 0;
				border-bottom: 1px solid $fontsecondgrey;
				@include transition;
				@include placeholder{
					color:$fontblack;
					@include transition;
				}
				&:focus{
					border-bottom: 1px solid $yellow;
					@include placeholder{
						color:$yellow;
					}
				}
			}
			textarea{
				resize: none;
				height: 37px;
			}
		}
		&_button{
			button{
				font-family: $poppinsregular;
				@include font-size(20,30);
				color:$yellow;
				width: 100%;
				border:1px solid $yellow;
				padding: 17px 15px;
				background: rgba(242,202,16,0.15);
				border-radius: 4px;
				i{
					font-size: 25px;
					margin-right: 10px;
				}
			}
		}
	}
}