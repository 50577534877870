.main-production-news{
	padding:83px 0 93px 0;
	@media (max-width: $md-width){
		padding: 50px 0;
	}
	h4{
		font-family: $poppinsbold;
		@include font-size(25,30);
		color:$fontblack;
		margin-bottom: 20px;
		@media (max-width: $sm-width){
			@include font-size(20,26);
		}
	}
	&__production{
		position: relative;
		@media (max-width: $md-width){
			margin-bottom: 40px;
		}
		figure{
			height: 251px;
			width: 100%;
			&:before{
				content:"";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: $black;
				opacity: 0.7;
				z-index: 0;
			}
		}
		.production__title{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			padding:25px 20px;
			h5{
				font-family: $poppinsbold;
				@include font-size(20,28);
				color:$white;
				position: absolute;
				left: 25px;
				bottom: 15px;
				&:before,&:after{
					display:inline-block;
				}
				&:before{
					content:"";
					position: absolute;
					bottom: 100%;
					margin-bottom: 15px;
					left: 0;
					width: 3px;
					height: 138px;
					background: $white;
				}
				&:after{
					content:"";
					position: absolute;
					bottom: 10px;
					width: 138px;
					height: 3px;
					background: $white;
					left: 100%;
					margin-left: 15px;
				}
				span{
					display: block;
				}
			}
			p{
				font-family: $poppinslight;
				@include font-size(15,20);
				color:$white;
				text-align: right;
				span{
					display: block;
				}
			}
		}
		.production-slide__control{
	        position: absolute;
		    right: 20px;
		    bottom: 20px;
		    width: 78px;
		    height: 37px;
		    a{
		    	width: 37px;
		    	height: 37px;
		    	border:1px solid $white !important;
		    	@include transition;
		    	opacity: 1;
		    	&:before{
		    		content:"\e900";
		    		font-family: 'icomoon';
		    		font-size: 18px;
		    		color:$white;
		    		display: inline-block;
		    		@include transition;
		    	}
		    	span{
		    		display: none;		
		    	}
		    	&.carousel-control-prev{
		    		margin-right: 4px;
		    		&:before{
		    			transform:rotate(180deg);
		    		}
		    	}
		    	&:hover{
		    		background:$white !important;
		    		&:before{
		    			color:$black;
		    		}
		    	}
		    }
		}
		.production__title{
			h5{
				opacity: 0;
				animation-duration: var(--animate-duration);
				animation-fill-mode: both;
				&:before,&:after{
					animation-duration: var(--animate-duration);
					animation-fill-mode: both;
				}
			}
			p{
				opacity: 0;
				animation-duration: var(--animate-duration);
				animation-fill-mode: both;
			}
		}
		.carousel-item{
			&.active{
				.production__title{
					h5{
						animation-name: fadeInDown;
						&:before{
							animation-name: beforeBorderAnimate;
							animation-delay: 0.5s;
						}
						&:after{
							animation-name: afterBorderAnimate;
							animation-delay: 0.7s;
						}
					}
					p{
						animation-name: fadeInUp;
					}
				}
			}
		}
	}
	&__news{
		background: $grey;
		ul{
			li{
				&:not(:last-child){
					border-bottom: 1px solid #d9d4d4;
				}
				a{
					padding: 26px 28px 26px 25px;
					display: flex;
					div{
						&.date{
							margin-right: 17px;
							border:1px solid $black;
							padding: 10px 6px;
							p{
								text-align: center;
								font-family: $poppinssemibold;
								@include font-size(9,15);
								color:$fontblack;
								span{
									display: block;
									@include font-size(33,30);
								}
							}
						}
						h5{
							font-family: $poppinssemibold;
							@include font-size(15,20);
							color:$fontblack;
							margin-bottom: 8px;
							display: -webkit-box;
						    -webkit-box-orient: vertical;
						    -webkit-line-clamp: 1;
						    overflow: hidden;
						}
						p{
							font-family: $poppinslight;
							@include font-size(12,15);
							color:$fontblack;
							display: -webkit-box;
						    -webkit-box-orient: vertical;
						    -webkit-line-clamp: 3;
						    overflow: hidden;
						}
					}
				}
			}
		}
		.news-event-slide__control{
	        position: absolute;
		    right:0;
		    top: -50px;
		    width: 78px;
		    height: 37px;
		    @media (max-width: $md-width){
		    	right: 20px;
		    }
		    a{
		    	width: 37px;
		    	height: 37px;
		    	border:1px solid $black !important;
		    	@include transition;
		    	opacity: 1;
		    	&:before{
		    		content:"\e900";
		    		font-family: 'icomoon';
		    		font-size: 18px;
		    		color:$black;
		    		display: inline-block;
		    		@include transition;
		    	}
		    	span{
		    		display: none;		
		    	}
		    	&.carousel-control-prev{
		    		margin-right: 4px;
		    		&:before{
		    			transform:rotate(180deg);
		    		}
		    	}
		    	&:hover{
		    		background:$black !important;
		    		&:before{
		    			color:$white;
		    		}
		    	}
		    }
		}
		ul{
			li{
				overflow: hidden;
				div{
					opacity: 0;
					animation-duration: var(--animate-duration);
					animation-fill-mode: both;
				}
			}
		}
		.carousel-item{
			&.active{
				ul{
					li{
						div{
							animation-name: fadeInUp;
							&.date{
								animation-name: fadeInLeft;
							}
						}
						&:last-child{
							div{
								animation-delay:0.3s;
							}
						}
					}
				}
			}
		}
	}
}